import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, IconButton, Paper, TextField, Typography, styled } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import SocketService from 'socketService';
import config from 'config';
import { chatCreate, chatList, groupChatCreate } from 'services/api/chat';
import { useParams } from 'react-router';
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'ui-component/AudioPlayer';
import Demo from 'ui-component/Demo';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
}));

const Message = () => {
    const [audio, setAudio] = useState([]);
    const [isPlaying, setsPlaying] = useState('');
    const [text, setText] = useState('');
    const { routeId, userName } = useParams();
    const socket = new SocketService(config.socketURL, { transports: ['websocket'] }); // Replace with your server's URL
    const audioContainerRef = useRef(null); // Ref for the audio container element
    let currentRouteId = window.sessionStorage.getItem("routeId");
    let user_name = window.sessionStorage.getItem("user_name");

    const recorderControls = useAudioRecorder(
        {
            noiseSuppression: true,
            echoCancellation: true,
        },
        (err) => alert('1') // onNotAllowedOrFound
    );

    useEffect(() => {
        chatList({
            route_id: currentRouteId
        }).then((res) => {
            // console.log(res.data.result.data);
            let msg = res.data.result.data.map((item) => {
                console.log(item.msg);
                return { sender_name: item.sender_name, message: item.msg, file: item.msg, type: item.message_type.toLowerCase(), is_sent: item.sender_id == currentRouteId }
            })
            setAudio(msg);
        })

        socket.connect();

        socket.on('messageSent', (data) => {

            // console.log('message sent', data);
            // console.log('current route', currentRouteId);
            // if (data.sender_id != currentRouteId) {
                setAudio((prevAudio) => [...prevAudio, { ...data, is_sent: false }]);
            // }
            if (data.type == "audio") {
                chatList({
                    route_id: currentRouteId,
                  }).then((res) => {
                    // console.log(res.data.result.data);
                    let msg = res.data.result.data.map((item) => {
                        return { sender_name: item.sender_name, message: item.msg, file: item.msg, type: item.message_type.toLowerCase(), is_sent: item.sender_id == currentRouteId }
                    })
                    setAudio(msg);

                })
            }
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth',
            });

        });

        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });

        return () => {
            socket.disconnect();
        };
    }, []);


    const addAudioElement = (blob) => {

        // emit event on pin update
        const audioFile = new File([blob], "audiofile.mp3", {
            type: "audio/mp3",
        });

        const url = URL.createObjectURL(blob);
        socket.emit('sendChat', { file: audioFile, type: 'audio', is_sent: true, receiver_id: routeId, sender_name: user_name, sender_id: currentRouteId });

        const formData = new FormData();
        formData.append("sender_id", currentRouteId);
        // formData.append("receiver_id", routeId);
        formData.append("message_type", 2);
        formData.append("msg", audioFile);

        groupChatCreate(formData).then((res) => {

        }).catch(e => {
            // console.log(e);
        })

        // Scroll to the very bottom of the audio container
        if (audioContainerRef.current) {
            audioContainerRef.current.scrollTop = audioContainerRef.current.scrollHeight;
        }
    };

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const sendTextMessage = () => {
        if (text.trim() !== '') {
            // Add the text message to the list of messages
            setAudio((prevAudio) => [...prevAudio, { message: text, type: 'text', is_sent: true }]);
            socket.emit('sendChat', { message: text, type: 'text', is_sent: true, receiver_id: routeId, sender_name: user_name, sender_id: currentRouteId });

            const formData = new FormData();
            formData.append("sender_id", currentRouteId);
            // formData.append("receiver_id", routeId);
            formData.append("message_type", 1);
            formData.append("msg", text);

            groupChatCreate(formData).then((res) => {
                // console.log(res);
                window.scrollTo({
                    top: document.documentElement.scrollHeight,
                    behavior: 'smooth',
                });

            }).catch(e => {
                // console.log(e);
            })


            // Clear the text input
            setText('');

            // Scroll to the very bottom of the audio container
            if (audioContainerRef.current) {
                audioContainerRef.current.scrollTop = audioContainerRef.current.scrollHeight;
            }
        }
    };

    return (
        // <Demo/>
        <div>
            <Typography
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBlock: 2,
                }}
                variant='h2'
                position='static'>
                {userName}
            </Typography>
            <Divider />
            <Box
                sx={{
                    p: 2,
                    borderRadius: 2,
                    bgcolor: 'background.default',
                    display: 'grid',
                    gap: 2,
                    paddingBottom: '100px',
                }}
            >
                {audio.map((message, index) => {
                    if (message.type === 'audio' && message.is_sent) {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    marginLeft: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography
                                    sx={{
                                        marginLeft: 'auto',
                                    }}
                                    variant='h4'>
                                    {message.sender_name}
                                </Typography>
                                <audio key={index} controls src={message.file} />
                            
                            </Box>
                        )
                    } else if (message.type === 'audio' && !message.is_sent) {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    marginLeft: '0'
                                }}
                            >
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        marginLeft: '0'
                                    }}
                                    variant='h4'>
                                    {message.sender_name}
                                </Typography>
                                <audio key={index} controls src={message.file} />
                                {/* <AudioPlayer key = {index} URL={message.file}/> */}
                            </Box>
                        )
                    } else {
                        return (
                            <>
                              
                            </>
                        );
                    }
                })}
            </Box>
            <Box
                position='fixed'
                bottom={0}
                color='primary'
                sx={{
                    backgroundColor: '#0005768f',
                    display: 'flex',
                    paddingBlock: 2,
                    flexGrow: 1,
                    alignItems: 'space-between',
                    width: '100%',
                }}
            >
                <AudioRecorder
                    classes='audio-recorder'
                    onRecordingComplete={(blob) => addAudioElement(blob)}
                    recorderControls={recorderControls}
                    showVisualizer={true}
                />
                
            </Box>
        </div>
    );
};

export default Message;
