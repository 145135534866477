import io from 'socket.io-client';

class SocketService {
  constructor(serverUrl) {
    this.socket = io(serverUrl);
  }

  connect() {
    // Connect to the Socket.io server
    this.socket.connect();
  }

  disconnect() {
    // Disconnect from the Socket.io server
    this.socket.disconnect();
  }

  on(event, callback) {
    // console.log(event);
    // Listen for a Socket.io event
    this.socket.on(event, callback);
  }

  off(event, callback) {
    // Remove a Socket.io event listener
    this.socket.off(event, callback);
  }

  emit(event, data) {
    // Send a Socket.io event to the server
    this.socket.emit(event, data);
  }
}

export default SocketService;
