import React, { useEffect, useRef, useState, useCallback } from 'react';
import useRecorder from './useRecorder';
import { Button, IconButton, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MicIcon from '@mui/icons-material/Mic'; // Replace 'Mic' with the actual Material-UI microphone icon
import DeleteIcon from '@mui/icons-material/Delete'; // Import the DeleteIcon from Material-UI
import axios from 'axios';
import { chatList, groupChatCreate } from 'services/api/chat';
import SocketService from 'socketService';
import config from 'config';

const TimerController = (props) => {
    const [renderedStreamDuration, setRenderedStreamDuration] = useState('00:00:00'),
        streamDuration = useRef(0),
        previousTime = useRef(0),
        requestAnimationFrameId = useRef(null),
        [isStartTimer, setIsStartTimer] = useState(false),
        [isStopTimer, setIsStopTimer] = useState(false),
        [isPauseTimer, setIsPauseTimer] = useState(false),
        [isResumeTimer, setIsResumeTimer] = useState(false),
        isStartBtnDisabled = isPauseTimer || isResumeTimer || isStartTimer,
        isStopBtnDisabled = !(isPauseTimer || isResumeTimer || isStartTimer);

    const updateTimer = useCallback(() => {
        let now = performance.now();
        let dt = now - previousTime.current;

        if (dt >= 1000) {
            streamDuration.current = streamDuration.current + Math.round(dt / 1000);
            const formattedStreamDuration = new Date(streamDuration.current * 1000).toISOString().substr(11, 8);
            setRenderedStreamDuration(formattedStreamDuration);
            previousTime.current = now;
        }
        requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
    }, []);

    const startTimer = useCallback(() => {
        previousTime.current = performance.now();
        requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
    }, [updateTimer]);

    useEffect(() => {
        if (props.record === true) {
            startHandler();
        } else {
            stopHandler();
        }
        if (isStartTimer && !isStopTimer) {
            startTimer();
        }
        if (isStopTimer && !isStartTimer) {
            streamDuration.current = 0;
            cancelAnimationFrame(requestAnimationFrameId.current);
            setRenderedStreamDuration('00:00:00');
        }
    }, [isStartTimer, isStopTimer, startTimer, props.record]);

    const startHandler = () => {
        setIsStartTimer(true);
        setIsStopTimer(false);
    };

    const stopHandler = () => {
        setIsStopTimer(true);
        setIsStartTimer(false);
        setIsPauseTimer(false);
        setIsResumeTimer(false);
    };

    return <div className="timer-controller-wrapper">{renderedStreamDuration}s</div>;
};

function Demo() {
    const [state, setState] = useState('');
    const [audios, setAudios] = useState([]);
    const [record, setRecord] = useState(false);
    const [play, setPlay] = useState(false);
    const [audioFile, setAudioFile] = useState(null); // State to hold the audio file
    let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();

    const socket = new SocketService(config.socketURL, { transports: ['websocket'] }); // Replace with your server's URL

    let currentRouteId = window.sessionStorage.getItem('routeId');
    let user_name = window.sessionStorage.getItem('user_name');

    const createAndSendAudioFile = async (url) => {
        // Create an HTTP GET request to fetch the audio data
        try {
            const response = await axios.get(url.data, { responseType: 'blob' });

            const timestamp = new Date().getTime();

            // Create a new File with the audio data and set it as the audio file
            const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
            const audioFile = new File([audioBlob], `${timestamp}.mp3`, { type: 'audio/mpeg' });
            setAudioFile(audioFile);

            // Now you can send this audioFile to your API using Axios or any other HTTP library
            const formData = new FormData();
            formData.append('sender_id', currentRouteId);
            // formData.append("receiver_id", routeId);
            formData.append('message_type', 2);
            formData.append('msg', audioFile);

            groupChatCreate(formData)
                .then((res) => {
                    // console.log(res);
                    socket.emit('sendChat', {
                        file: audioFile,
                        type: 'audio',
                        is_sent: true,
                        receiver_id: null,
                        sender_name: user_name,
                        sender_id: currentRouteId
                    });
                })
                .catch((e) => {
                    console.error('Error in API call:', e);
                });

            // If the API request is successful, you can handle the response here
        } catch (error) {
            // Handle errors here
            console.error('Error sending audio file to API:', error);
        }
    };

    useEffect(() => {
        chatList({
            route_id: currentRouteId
        }).then((res) => {
            let msg = res.data.result.data.map((item) => {
                console.log();
                const updatedAudios = [...audios, item.msg];
                setAudios(updatedAudios);
                // setAudios([...audios, item.msg]);
                // return {
                //     sender_name: item.sender_name,
                //     message: item.msg,
                //     file: item.msg,
                //     type: item.message_type.toLowerCase(),
                //     is_sent: item.sender_id == currentRouteId
                // };
            });
            console.log(audios);
        });
    }, []);

    useEffect(() => {
        console.log(audioURL);
        if (audios.length <= 0) {
            setAudios([audioURL]);
        } else {
            setAudios([...audios, audioURL]);
        }

        if (audioURL) {
            createAndSendAudioFile(audioURL); // Call the function when a new audioURL is generated
        }
    }, [audioURL]);

    function Buttonstart() {
        setState('red');
        setRecord(true);
        startRecording();
    }

    async function Buttonstop() {
        if (isRecording === true) {
            let recording = await stopRecording();
            console.log('recording: ', recording);
            setState('#4695da');
            setRecord(false);
        } else {
            // alert('Give permission audio to record');
        }
    }

    function Deletedata(id) {
        const data = audios.filter((e, index) => {
            return index !== id;
        });
        setAudios(data);
    }

    function stateaudio(e, index) {
        if (e.target.classList.contains('fa-play')) {
            e.target.classList.remove('fa-play');
            e.target.classList.add('fa-pause');
        } else if (e.target.classList.contains('fa-pause')) {
            e.target.classList.remove('fa-pause');
            e.target.classList.add('fa-play');
        }

        var myAudio = document.getElementById(`audioId${index}`);
        return myAudio.paused ? myAudio.play() : myAudio.pause();
    }
    console.log(audios);
    
    return (
        <div className="App">
            {audios.map((res, index) =>
                index !== 0
                    ? res && (
                          <div
                              key={index}
                              style={{
                                  border: '2px solid #000567',
                                  borderRadius: '20px',
                                  margin: '10px',
                                  width: '60%',
                                  height: 70,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-around',
                                  flexDirection: 'row'
                              }}
                          >
                              <div>
                                  <div hidden>
                                      <audio
                                          id={`audioId${index}`}
                                          onEnded={() => {
                                              if (document.getElementById(`playAudio${index}`).classList.contains('fa-pause')) {
                                                  document.getElementById(`playAudio${index}`).classList.add('fa-play');
                                                  document.getElementById(`playAudio${index}`).classList.remove('fa-pause');
                                              }
                                          }}
                                          src={res.data}
                                          controls
                                          type="audio/mp3"
                                      />
                                  </div>
                                  <div>
                                      <IconButton
                                          style={{ backgroundColor: '#000567', color: 'white' }}
                                          id={`playAudio${index}`}
                                          onClick={(e) => stateaudio(e, index)}
                                      >
                                          <PlayArrowIcon />
                                      </IconButton>
                                  </div>
                              </div>
                              <Typography variant="h4">Dhaval Soni</Typography>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right' }}>
                                  <IconButton
                                      variant="outlined"
                                      color="danger"
                                      onClick={() => Deletedata(index)}
                                      style={{ color: 'danger' }}
                                  >
                                      <DeleteIcon />
                                  </IconButton>
                                  <Typography variant="h6">12:00</Typography>
                              </div>
                          </div>
                      )
                    : null
            )}
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#000567'
                }}
            >
                <Button
                    style={{
                        background: record === '' ? '#4695da' : record,
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 'auto'
                    }}
                    onClick={() => {
                        Buttonstart();
                        setTimeout(() => Buttonstop(), 500);
                    }}
                >
                    {record ? (
                        <span style={{ marginLeft: '20px' }}>
                            <TimerController record={record} />
                        </span>
                    ) : (
                        <IconButton>
                            <MicIcon sx={{ color: 'white' }} />
                        </IconButton>
                    )}
                </Button>
            </div>
        </div>
    );
}
export default Demo;
