import axiosClient from "../axios";

export const routeList = (data) => axiosClient.get('routes', data);
export const routeCreate = (data) => axiosClient.post('punch-in', data);
export const routeComplete = (id, data) => axiosClient.put(`punch-out/${id}`, data);
export const addPin = (data) => axiosClient.post('pin', data);
// export const adminUpdate = (id, data) => axiosClient.put(`admins/${id}`, data, {
//     headers: {
//         'Content-Type': 'application/json',
//     }
// });
