const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/localhost',
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    projectName: 'Kuwait Army Live Map',
    socketURL: 'https://tech-and-beyond.org',
    // socketURL: 'https://njs.khalifaalghanimknpc.com:8000',
    baseUrl: 'https://moi-live-map.tech-and-beyond.com/'
};

export default config;
